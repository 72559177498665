// external
import React from 'react';
import { Box, Typography, useTheme, SxProps, Theme } from '@mui/material';

// internal
import { buildSanityImageUrl } from '@guider-global/sanity-hooks';
import { useMobileMediaQuery } from 'hooks';

// types
import { SanityLandingPageSingleQuote } from '@guider-global/shared-types';
import { AnimationWrapper } from 'components/AnimationWrapper';

interface ILandingPageSingleQuoteProps {
  config: SanityLandingPageSingleQuote;
}

export const LandingPageSingleQuote: React.FunctionComponent<
  ILandingPageSingleQuoteProps
> = ({ config }) => {
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const isTransparent = config.transparent_image;

  const verticalPosition = isTransparent && config?.vertical_position;

  const nonTransparentStyles = { objectFit: 'cover', borderRadius: '8px' };

  let imagePosition: string;
  let mobileDirection: string;
  let imageMargin: { mt?: string; my?: string; mb?: string };

  switch (verticalPosition) {
    case 'top':
      imagePosition = 'flex-start';
      mobileDirection = 'column';
      imageMargin = { mb: '32px' };
      break;
    case 'bottom':
      imagePosition = 'flex-end';
      mobileDirection = 'column-reverse';
      imageMargin = isMobile ? { mt: '16px' } : { mt: '32px' };
      break;
    default:
      imagePosition = 'center';
      mobileDirection = 'column-reverse';
      imageMargin = isMobile ? { mb: '32px' } : { my: '32px' };
      break;
  }

  const quoteSourceMobileStyles: SxProps<Theme> = {
    alignItems: 'center',
    py: 3,
    px: 4,
    pl: 4,
    '&::before': {
      left: 'auto',
      height: '4px',
      width: '90%',
      content: '""',
      position: 'absolute',
      top: 3,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '12px',
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: '#333333',
        width: '100%',
        maxWidth: '100%',
        px: 3,
        pt: isMobile && verticalPosition !== 'top' ? 6 : 0,
        display: 'flex',
        justifyContent: isMobile ? imagePosition : 'center',
        alignItems: isMobile ? 'center' : imagePosition,
        flexDirection: isMobile ? mobileDirection : 'row',
        textAlign: isMobile ? 'center' : 'left',
        overflow: 'hidden',
      }}
    >
      <AnimationWrapper
        animationType="fade"
        screenHeightTrigger={20}
        sx={{
          maxWidth: isMobile ? '80%' : '30%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: imagePosition,
          ...imageMargin,
          ...(!isTransparent && nonTransparentStyles),
        }}
      >
        <Box
          component="img"
          src={
            config.source_image
              ? buildSanityImageUrl({
                  source: config.source_image,
                  width: 660,
                })
              : undefined
          }
          alt="quote source"
          sx={{
            maxHeight: '300px',
            maxWidth: '660px',
          }}
        />
      </AnimationWrapper>
      <AnimationWrapper
        animationType="fade"
        screenHeightTrigger={20}
        sx={{
          maxWidth: isMobile ? '100%' : '50%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: isMobile ? 'center' : 'flex-start',
          ...(!isMobile ? { my: '64px', mx: '24px' } : { mx: '16px' }),
        }}
      >
        <Typography color="white" variant="h6">
          {config.quote}
        </Typography>
        <Box
          sx={{
            mt: 3,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            py: 1,
            px: 0,
            pl: 3,
            width: 'auto',
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              left: 0,
              top: 0,
              height: '100%',
              width: '4px',
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '12px',
            },
            ...(isMobile && quoteSourceMobileStyles),
          }}
        >
          <Typography color="white" variant="h6" pb={0.5}>
            {config.source}
          </Typography>
          <Typography color="white" variant="subtitle2">
            {config.source_description}
          </Typography>
        </Box>
      </AnimationWrapper>
    </Box>
  );
};

export default LandingPageSingleQuote;
