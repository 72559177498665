import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';

import { SanityLandingPageFaqs } from '@guider-global/shared-types';

import { AnimationWrapper } from 'components/AnimationWrapper';
import { Markdown } from 'components';

export interface FaqQuestionColumnProps {
  questions: SanityLandingPageFaqs['faqQuestions'];
}

const FaqQuestionColumn: React.FC<FaqQuestionColumnProps> = ({ questions }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
        textAlign: 'left',
        py: 8,
      })}
    >
      {questions.map(({ question, answer }, index) => {
        return (
          <AnimationWrapper
            key={`faq-question-${index}`}
            animationType="fade"
            animationDelay={500}
            sx={{ width: '100%' }}
          >
            <Accordion
              elevation={0}
              sx={{
                border: '1px solid #E0E0E0',
              }}
            >
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
                sx={(theme) => ({
                  '&.MuiAccordionSummary-root': {
                    gap: theme.spacing(1),
                  },
                })}
              >
                <Typography variant="body1">{question}</Typography>
              </AccordionSummary>
              <AccordionDetails id={`panel${index + 1}-content`}>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{
                    whiteSpace: 'pre-wrap',
                    'ul, ol': {
                      my: -2,
                    },
                  }}
                >
                  <Markdown>{answer ?? ''}</Markdown>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </AnimationWrapper>
        );
      })}
    </Box>
  );
};

export default FaqQuestionColumn;
