import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { HowItWorksStep as SanityHowItWorksStep } from '@guider-global/shared-types';

import HowItWorksStep from './HowItWorksStep';

export interface HowItWorksStepsProps {
  steps: SanityHowItWorksStep[];
}

const HowItWorksStepsRow: FunctionComponent<HowItWorksStepsProps> = ({
  steps,
}) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, 1fr)',
          gridTemplateRows: '1fr',
          gridAutoFlow: 'column',
          padding: theme.spacing(0, 3),
          gap: theme.spacing(3),
          [theme.breakpoints.down('md')]: {
            gap: theme.spacing(2),
            padding: theme.spacing(0, 3),
            gridAutoFlow: 'row',
          },
        })}
      >
        {steps.map((step, index) => (
          <HowItWorksStep
            step={step}
            position={index + 1}
            key={`${index}-how-it-works-step`}
          />
        ))}
      </Box>
    </>
  );
};

export default HowItWorksStepsRow;
