/* eslint-disable camelcase */
import React from 'react';
// external
import { Box, Typography } from '@mui/material';

// internal
import { buildSanityImageUrl } from '@guider-global/sanity-hooks';

// types
import { SanityLandingPageLogos } from '@guider-global/shared-types';

interface ILandingPageLogosProps {
  config: SanityLandingPageLogos;
}

export const LandingPageLogos: React.FunctionComponent<
  ILandingPageLogosProps
> = ({ config }) => {
  const renderListLogos = () =>
    config &&
    config?.logos &&
    config?.logos?.length !== 0 && (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {config?.logos?.map((logo, index) => (
          <img
            key={`hero-logo-${index}`}
            src={buildSanityImageUrl({ source: logo.image, format: 'svg' })}
            alt={logo.title}
            style={{
              margin: '16px 30px',
              maxWidth: '120px',
              maxHeight: '70px',
            }}
          />
        ))}
      </Box>
    );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          my: 5,
        }}
      >
        {config?.title && (
          <Typography
            variant="overline"
            textAlign="center"
            sx={{ maxWidth: '90%' }}
          >
            {config?.title}
          </Typography>
        )}
        {renderListLogos()}
      </Box>
    </>
  );
};

export default LandingPageLogos;
