import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { stripHostIfInternalUrl } from '@guider-global/front-end-utils';

export interface MarkdownProps {
  children?: string;
}
export function Markdown({ children }: MarkdownProps) {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        ul: ({ node, ...props }) => (
          <div style={{ whiteSpace: 'normal' }}>
            <ul {...props} />
          </div>
        ),
        ol: ({ node, ...props }) => (
          <div style={{ whiteSpace: 'normal' }}>
            <ol {...props} />
          </div>
        ),
        a: ({ href, children }) =>
          href ? (
            <Link to={stripHostIfInternalUrl(href)}>{children}</Link>
          ) : null,
      }}
    >
      {children ?? ''}
    </ReactMarkdown>
  );
}
