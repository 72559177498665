// external
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// types
import { SanityLandingPageTestimonials } from '@guider-global/shared-types';

import { useMobileMediaQuery } from 'hooks';

import { AnimationWrapper } from 'components/AnimationWrapper';

import { useSettings } from '@guider-global/sanity-hooks';
import { getImageSource } from 'utils/getImageSource';

interface ILandingPageTestimonialsProps {
  config: SanityLandingPageTestimonials;
}

export const LandingPageTestimonials: React.FunctionComponent<
  ILandingPageTestimonialsProps
> = ({ config }) => {
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  const { settings } = useSettings({
    localeCode: 'en_GB',
  });

  const swiperStyles = {
    '.swiper-wrapper': {
      alignItems: 'center',
    },
    '.swiper-pagination': {
      position: 'relative',
    },
    '.swiper-pagination-bullet': {
      width: '13px',
      height: '13px',
    },
    '.swiper-pagination-bullet-active': {
      backgroundColor: '#757575',
    },
  };

  const renderTestimonials = () => {
    return (
      <Swiper
        centeredSlides={true}
        spaceBetween={50}
        slidesPerView={1}
        modules={[Pagination, EffectFade, Autoplay]}
        pagination={{
          clickable: true,
        }}
        effect={'fade'}
        autoplay={{
          delay: 7000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        loop={true}
        fadeEffect={{
          crossFade: true,
        }}
      >
        {config?.blocks?.map((testimonial, index) => {
          const renderSourceData = () => (
            <Box sx={{ ml: isMobile ? 2 : 0 }}>
              <Typography variant="body1" sx={{ mt: isMobile ? 0 : 3 }}>
                {testimonial.source}
              </Typography>
              {testimonial.source_description && (
                <Typography
                  component="p"
                  variant="caption"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {testimonial.source_description}
                </Typography>
              )}
              {testimonial.additional_source_description && (
                <Typography
                  component="p"
                  variant="caption"
                  color="secondary.dark"
                >
                  {testimonial.additional_source_description}
                </Typography>
              )}
            </Box>
          );

          return (
            <SwiperSlide key={`testimonial-${index}`}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  px: 2,
                  pb: isMobile ? 6 : 0,
                }}
              >
                <Box
                  sx={{
                    width: isMobile ? '100%' : '55%',
                    mb: isMobile ? 5 : 0,
                  }}
                >
                  <Box
                    component="img"
                    src={getImageSource({
                      source:
                        settings?.static_media?.landing_page?.quotation_mark,
                    })}
                    alt="quotes"
                    sx={{ width: '90px' }}
                  />
                  <AnimationWrapper animationType="fade" animationDelay={500}>
                    <Typography variant="h5" sx={{ mt: 6 }}>
                      {testimonial.quote_title}
                    </Typography>
                    {testimonial.quote_body && (
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ mt: 2 }}
                      >
                        {testimonial.quote_body}
                      </Typography>
                    )}
                    {!isMobile && renderSourceData()}
                  </AnimationWrapper>
                </Box>
                <AnimationWrapper
                  animationType={isMobile ? 'fade' : 'both'}
                  slideDirection="left"
                  animationDelay={500}
                  screenHeightTrigger={1}
                  slideOffset={10}
                  sx={{
                    maxWidth: isMobile ? '100%' : '35%',
                    width: isMobile ? '100%' : 'auto',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component="img"
                    src={getImageSource({
                      source: testimonial.source_image,
                      width: 660,
                    })}
                    alt={testimonial.source}
                    sx={{
                      borderRadius: '8px',
                      objectFit: 'cover',
                      ...(isMobile
                        ? {
                            minHeight: '148px',
                            minWidth: '148px',
                            maxHeight: '148px',
                            maxWidth: '148px',
                          }
                        : { height: '380px', width: '330px' }),
                    }}
                  />
                  {isMobile && renderSourceData()}
                </AnimationWrapper>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  };

  return (
    <Box
      sx={{
        pt: isMobile ? 12 : 16,
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderTop: '300px solid transparent',
          borderLeft: '100vw solid #f5f5f5',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pb: isMobile ? 4 : 8,
      }}
    >
      <Box
        sx={{
          width: isMobile ? '95%' : '70%',
          maxWidth: '1300px',
          ...swiperStyles,
        }}
      >
        {renderTestimonials()}
      </Box>
    </Box>
  );
};

export default LandingPageTestimonials;
