import { Box, BoxProps, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import IntegrationConnectedButton, {
  IntegrationConnectedButtonProps,
} from './IntegrationConnectedButton';

export interface IntegrationsCardProps extends IntegrationConnectedButtonProps {
  iconSource: string | undefined;
  header: string | undefined;
  subheader: string | undefined;
  enabled: boolean;
}

export const IntegrationsCard: FC<IntegrationsCardProps> = ({
  iconSource,
  header,
  subheader,
  connected,
  onClickConnect,
  onClickDisconnect,
  enabled,
  loading,
}) => {
  if (!enabled) return <></>;
  return (
    <CardRootContainer>
      <CardIconAndDetailsContainer>
        <CardIconContainer>
          <Box
            component="img"
            src={iconSource}
            alt={`${header} Icon`}
            sx={{}}
          />
        </CardIconContainer>
        <CardDetailsContainer>
          <Typography variant="body2" component="h3" color="text.secondary">
            {header}
          </Typography>
          {loading ? (
            <Skeleton height={24} width={200} />
          ) : (
            <Typography variant="body1" component="h4" color="text.primary">
              {subheader}
            </Typography>
          )}
        </CardDetailsContainer>
      </CardIconAndDetailsContainer>
      <IntegrationConnectedButton
        loading={loading}
        connected={connected}
        onClickDisconnect={onClickDisconnect}
        onClickConnect={onClickConnect}
      />
    </CardRootContainer>
  );
};

const CardRootContainer: FC<BoxProps> = ({ children, ...rest }) => (
  <Box
    sx={{
      display: 'flex',
      flexFlow: { xs: 'column nowrap', md: 'row nowrap' },
      justifyContent: { xs: 'center', md: 'space-between' },
      alignItems: 'center',
      boxShadow: '0px 0px 0px 1px #E0E0E0',
      borderRadius: '4px',
      gap: { xs: 3, md: 0 },
      p: 2,
    }}
    {...rest}
  >
    {children}
  </Box>
);

const CardIconAndDetailsContainer: FC<BoxProps> = ({ children, ...rest }) => (
  <Box
    sx={{
      display: 'flex',
      flexFlow: { xs: 'column nowrap', md: 'row nowrap' },
      justifyContent: { xs: 'center', md: 'flex-start' },
      alignItems: 'center',
      gap: 2,
    }}
    {...rest}
  >
    {children}
  </Box>
);

const CardDetailsContainer: FC<BoxProps> = ({ children, ...rest }) => (
  <Box
    sx={{
      display: 'flex',
      flexFlow: 'Column nowrap',
      justifyContent: 'center',
      alignItems: { xs: 'center', md: 'stretch' },
      textAlign: { md: 'start', xs: 'center' },
    }}
    {...rest}
  >
    {children}
  </Box>
);

const CardIconContainer: FC<BoxProps> = ({ children, ...rest }) => (
  <Box
    sx={{
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: { xs: '52px', md: '48px' },
      height: { xs: '52px', md: '48px' },
      minWidth: { xs: '52px', md: '48px' },
      backgroundColor: 'grey.100',
      borderRadius: '4px',
    }}
    {...rest}
  >
    {children}
  </Box>
);
