// external
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuth } from '@guider-global/auth-hooks';

// store
import { useAppSelector } from 'store/hooks';
import { selectRegistrationType } from 'store/slices/appSlice';

// types
import { ICallToActionConfig } from '..';
import { useMixpanelEvents } from 'hooks';

export interface NavigationToProgramButtonProps {
  ctaConfig?: ICallToActionConfig;
  buttonColor: 'secondary' | 'info';
  role: 'guide' | 'trainee';
  onClickProgramButtons: (isGuide?: boolean, pathname?: string) => void;
}

export const NavigationToProgramButton: FC<NavigationToProgramButtonProps> = ({
  ctaConfig,
  buttonColor,
  role,
  onClickProgramButtons,
}) => {
  const { trackScreenTimeAndClick } = useMixpanelEvents();
  const location = useLocation();
  const pathname = location.pathname;

  const { isAuthenticated } = useAuth({});

  const registrationPageType = useAppSelector(selectRegistrationType);
  const showLoginButtons = registrationPageType !== 'none';
  const isGuide = role === 'guide';

  if (!isAuthenticated && !showLoginButtons) return <></>;

  return (
    <Button
      data-cy="components_LandingPage_ProgramLandingPageHero_program-registration-button"
      variant="contained"
      color={buttonColor}
      sx={{ color: 'white', zIndex: 1 }}
      fullWidth
      onClick={() => {
        if (isGuide) {
          trackScreenTimeAndClick('Program Landing page - Hero - Be a Guide');
        } else {
          trackScreenTimeAndClick('Program Landing page - Hero - Be a Trainee');
        }
        onClickProgramButtons(isGuide, pathname);
      }}
    >
      {ctaConfig?.cta_button_label}
    </Button>
  );
};
