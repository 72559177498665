import { Typography } from '@mui/material';
import { Markdown } from 'components';
import { FunctionComponent } from 'react';

export interface HowItWorksDescriptionProps {
  description: string;
}

const HowItWorksDescription: FunctionComponent<HowItWorksDescriptionProps> = ({
  description,
}) => {
  return (
    <Typography
      variant="body2"
      component="span"
      sx={{
        '> p': {
          margin: 0,
        },
        color: 'white',
        whiteSpace: 'pre-wrap',
      }}
    >
      <Markdown>{description}</Markdown>
    </Typography>
  );
};

export default HowItWorksDescription;
