import { FC, PropsWithChildren } from 'react';

import { useMobileMediaQuery } from 'hooks';

import { Divider, Typography } from '@mui/material';

const FaqHeader: FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useMobileMediaQuery();
  const breakTitleOnMobile = isMobile ? { whiteSpace: 'break-spaces' } : {};

  return (
    <Divider>
      <Typography variant="h4" sx={{ ...breakTitleOnMobile }}>
        {children}
      </Typography>
    </Divider>
  );
};

export default FaqHeader;
