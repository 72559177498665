import Message from '@mui/icons-material/Message';
import { Alert, Box } from '@mui/material';
import { Button, Text, AvatarBox, UpdateIcon } from '@guider-global/ui';

export interface MaintenanceViewProps {
  heading: string;
  alertContent: string;
  supportButtonLabel: string;
  description: string;
  isMobile?: boolean;
  onClickSupport?: () => void;
  href?: string;
}

export const MaintenanceView = ({
  heading,
  alertContent,
  supportButtonLabel,
  description,
  isMobile,
  onClickSupport,
  href,
}: MaintenanceViewProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1.5rem',
        alignSelf: 'stretch',
        padding: '4rem 0rem',
      }}
    >
      <AvatarBox
        icon={<UpdateIcon sx={{ color: 'info' }} />}
        size={isMobile ? 'medium' : 'large'}
        heading={heading}
      />
      <Alert severity="info" color="info">
        {alertContent}
      </Alert>
      <Text variant="body1" text={description} />
      <Button
        variant="outlined"
        label={supportButtonLabel}
        startIcon={<Message />}
        color="info"
        onClick={onClickSupport}
        href={href}
      />
    </Box>
  );
};
