/* eslint-disable camelcase */

// external
import React from 'react';
import { useLocation } from 'react-router-dom';

// internal
import { buildSanityImageUrl } from '@guider-global/sanity-hooks';
import { useMixpanelEvents, useMobileMediaQuery } from 'hooks';

// Components
import { Box, Button, Typography, lighten } from '@mui/material';
import { AnimationWrapper } from 'components/AnimationWrapper';

// types
import { SanityLandingPageCallToAction } from '@guider-global/shared-types';
import { ILoginRegisterButtonProps } from '../LandingPageContent';
import { getContrastColor } from '@guider-global/ui';

interface ILandingPageCallToActionProps extends ILoginRegisterButtonProps {
  config: SanityLandingPageCallToAction;
}

export const LandingPageCallToAction: React.FunctionComponent<
  ILandingPageCallToActionProps
> = ({
  config,
  onClickProgramButtons,
  onClickRegisterButton,
  showLoginButtons,
}) => {
  const { trackScreenTimeAndClick } = useMixpanelEvents();
  // Styling
  const isMobile = useMobileMediaQuery();

  const showButtons = config.show_buttons;
  const backgroundColor = config.background_color?.hex ?? '#333333';
  const textColor = getContrastColor(backgroundColor);
  const backgroundRgb = config.background_color?.rgb || {
    r: 51,
    g: 51,
    b: 51,
  };
  const backgroundGradient = `radial-gradient(
    ellipse at 50% 50%,
    rgba(${backgroundRgb.r}, ${backgroundRgb.g}, ${backgroundRgb.b}, 1) 0%,
    rgba(${backgroundRgb.r}, ${backgroundRgb.g}, ${backgroundRgb.b}, 0.5) 50%,
    rgba(${backgroundRgb.r}, ${backgroundRgb.g}, ${backgroundRgb.b}, 0) 85%
  )`;

  // Router
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `radial-gradient(${lighten(
          backgroundColor,
          0.3,
        )} 0.45px,${backgroundColor} 1px)`,
        backgroundSize: '12px 12px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: 8,
          background: backgroundGradient,
        }}
      >
        <Box
          sx={{
            width: '90%',
            maxWidth: '1800px',
            display: 'flex',
            justifyContent:
              isMobile || !showButtons ? 'center' : 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={
              isMobile || !showButtons
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    px: 2,
                  }
                : {}
            }
          >
            {config.logo && (
              <AnimationWrapper animationType="fade">
                <Box
                  component="img"
                  src={buildSanityImageUrl({ source: config.logo })}
                  alt=""
                  sx={{
                    maxWidth: '160px',
                    maxHeight: '50px',
                    marginBottom: '16px',
                  }}
                />
              </AnimationWrapper>
            )}
            <AnimationWrapper animationType="fade">
              <Typography
                color={textColor}
                variant="h5"
                sx={{
                  mr: isMobile || !showButtons ? 0 : 10,
                  textAlign: isMobile ? 'center' : 'left',
                }}
              >
                {config.title}
              </Typography>
            </AnimationWrapper>
          </Box>
          {!isMobile && showLoginButtons && showButtons && (
            <AnimationWrapper animationType="fade" animationDelay={500}>
              {config.button_1_label && (
                <Button
                  data-cy="components_LandingPage_LandingPageCallToAction_register-button-1"
                  variant="contained"
                  color="info"
                  onClick={() => {
                    trackScreenTimeAndClick(
                      'Organization Landing page - CTA - Register Button',
                    );
                    onClickRegisterButton();
                  }}
                  size="large"
                  sx={{ minWidth: '180px' }}
                >
                  {config.button_1_label}
                </Button>
              )}
              {config.button_2_label && onClickProgramButtons && (
                <Button
                  data-cy="components_LandingPage_LandingPageCallToAction_guide-register-button-2"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    trackScreenTimeAndClick(
                      'Program Landing page - CTA - Be a Guide',
                    );
                    onClickProgramButtons(true, pathname);
                  }}
                  sx={{ color: 'white', minWidth: '180px' }}
                >
                  {config.button_2_label}
                </Button>
              )}
              {config.button_3_label && onClickProgramButtons && (
                <Button
                  data-cy="components_LandingPage_LandingPageCallToAction_trainee-register-button-3"
                  variant="contained"
                  color="info"
                  onClick={() => {
                    trackScreenTimeAndClick(
                      'Program Landing page - CTA - Be a Trainee',
                    );
                    onClickProgramButtons(false, pathname);
                  }}
                  sx={{ ml: 2, minWidth: '180px' }}
                >
                  {config.button_3_label}
                </Button>
              )}
            </AnimationWrapper>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPageCallToAction;
