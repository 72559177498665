import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { HowItWorksMedia as SanityHowItWorksMedia } from '@guider-global/shared-types';

import { buildSanityImageUrl } from '@guider-global/sanity-hooks';

export interface HowItWorksImageProps {
  image?: SanityHowItWorksMedia['image'];
}

const HowItWorksImage: FunctionComponent<HowItWorksImageProps> = ({
  image,
}) => {
  if (!image) return <></>;
  return (
    <>
      <Box
        component={'img'}
        src={buildSanityImageUrl({ source: image.image, width: 1000 })}
        alt={image.imageAltText}
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: image.transparentImage ? 0 : '8px',
        }}
      />
    </>
  );
};

export default HowItWorksImage;
