/* eslint-disable camelcase */

// external
import React, { useCallback } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

// internal
import { buildSanityImageUrl } from '@guider-global/sanity-hooks';
import { useMixpanelEvents, useMobileMediaQuery } from 'hooks';
import { hexToRGB } from 'utils';

// components
import { IconCircle } from 'components/Icon';
import { AnimationWrapper } from 'components/AnimationWrapper';

// types
import { SanityLandingPageBenefits } from '@guider-global/shared-types';
import { SanityIcon } from '@guider-global/shared-types/lib/sanity/SanityIcon';
import { ILoginRegisterButtonProps } from '../LandingPageContent';

interface ILandingPageBenefitsProps extends ILoginRegisterButtonProps {
  config: SanityLandingPageBenefits;
}

export const LandingPageBenefits: React.FunctionComponent<
  ILandingPageBenefitsProps
> = ({
  config,
  onClickProgramButtons,
  onClickRegisterButton,
  showLoginButtons,
}) => {
  const { trackScreenTimeAndClick } = useMixpanelEvents();
  // Styling
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  // Router
  const location = useLocation();
  const pathname = location.pathname;

  const renderPoint = useCallback(
    (
      point: { title?: string; icon?: SanityIcon },
      index: number,
      side: 'left' | 'right',
    ) => {
      const isLeft = side === 'left';
      const color = isLeft
        ? theme.palette.success.main
        : theme.palette.info.main;

      const leftAfterStyles = {
        borderRight: '2px dotted',
        left: '100%',
        borderBottomRightRadius: '8px',
      };
      const rightAfterStyles = {
        borderLeft: '2px dotted',
        right: '100%',
        borderBottomLeftRadius: '8px',
      };
      const afterStyles = isLeft ? leftAfterStyles : rightAfterStyles;
      const isFirst = index === 0;
      const desktopStyles = {
        flexDirection: isLeft ? 'row' : 'row-reverse',
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '26px',
          borderBottom: '2px dotted',
          height: isFirst ? '73px' : '98px',
          bottom: '31px',
          ...afterStyles,
          borderColor: `rgba(${hexToRGB(color)}, 0.5)`,
        },
        ...(isFirst && {
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '35px',
            height: '5px',
            bottom: 'calc(100% + 36px)',
            borderRadius: '12px',
            backgroundColor: color,
            ...(isLeft
              ? { left: 'calc(100% + 9px)' }
              : { right: 'calc(100% + 9px)' }),
          },
        }),
      };

      return (
        <AnimationWrapper
          animationType={isMobile ? 'fade' : 'both'}
          slideDirection={isLeft ? 'right' : 'left'}
          key={`${side}-${index}`}
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: `rgba(${hexToRGB(color)}, 0.08)`,
            borderRadius: '8px',
            mt: 3,
            width: '420px',
            maxWidth: '100%',
            ...(!isMobile && desktopStyles),
          }}
        >
          <Typography variant="body1">{point.title}</Typography>
          <IconCircle icon={point.icon} color={color} size="small" />
        </AnimationWrapper>
      );
    },
    [isMobile, theme.palette.info.main, theme.palette.success.main],
  );

  const renderPoints = useCallback(
    (
      points: {
        title?: string;
        icon?: SanityIcon;
      }[],
      side: 'right' | 'left',
    ) => points.map((point, index) => renderPoint(point, index, side)),
    [renderPoint],
  );

  const renderSection = useCallback(
    (side: 'left' | 'right') => {
      const isLeft = side === 'left';
      const mobileStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        maxWidth: '100%',
        mt: 5,
      };

      return (
        <Box
          sx={{
            textAlign: isLeft ? 'right' : 'left',
            maxWidth: '35%',
            ...(isMobile && mobileStyles),
          }}
        >
          <AnimationWrapper
            slideDirection={isLeft ? 'right' : 'left'}
            animationType={isMobile ? 'fade' : 'both'}
          >
            <Typography component="h4" variant="h5" sx={{ mx: 1.5 }}>
              {config[side]?.title}
            </Typography>
          </AnimationWrapper>
          {renderPoints(config[side]?.points ?? [], side)}
        </Box>
      );
    },
    [config, isMobile, renderPoints],
  );

  const renderImage = useCallback(
    () => (
      <Box
        sx={{
          mx: 7,
          maxWidth: '30%',
        }}
      >
        <AnimationWrapper
          animationType="fade"
          sx={{
            width: '100%',
            height: '100%',
            px: 1.5,
            py: 1.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src={
              config.image
                ? buildSanityImageUrl({ source: config.image })
                : undefined
            }
            alt=""
            sx={{ maxWidth: '220px', maxHeight: '280px' }}
          />
        </AnimationWrapper>
      </Box>
    ),
    [config.image],
  );

  const renderDescription = useCallback(() => {
    const mobileStyles = {
      my: 4,
      px: 2,
    };

    return (
      <Typography
        color="text.secondary"
        variant="body1"
        sx={{
          width: '550px',
          maxWidth: '100%',
          textAlign: 'center',
          mt: 6,
          ...(isMobile && mobileStyles),
        }}
      >
        {config.description}
      </Typography>
    );
  }, [config.description, isMobile]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 8,
        px: isMobile ? 0 : 4,
      }}
    >
      <AnimationWrapper
        animationType="fade"
        sx={{
          width: '550px',
          maxWidth: '100%',
        }}
      >
        <Typography
          component="h3"
          variant="h4"
          sx={{
            mb: isMobile ? 0 : 6,

            textAlign: 'center',
            px: 2,
          }}
        >
          {config.title}
        </Typography>
      </AnimationWrapper>
      {isMobile && renderDescription()}
      {isMobile && renderImage()}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
          maxWidth: '100%',
          px: 2,
        }}
      >
        {renderSection('left')}
        {!isMobile && renderImage()}
        {renderSection('right')}
      </Box>
      {!isMobile && renderDescription()}
      {!isMobile && showLoginButtons && config.show_buttons && (
        <Box sx={{ mt: 4 }}>
          {config.button_1_label && (
            <Button
              data-cy="components_LandingPage_LandingPageBenefits_register-button-1"
              variant="contained"
              color="info"
              onClick={() => {
                trackScreenTimeAndClick(
                  'Organization Landing page - Benefits - Register Button',
                );
                onClickRegisterButton();
              }}
              size="large"
              sx={{ minWidth: '180px' }}
            >
              {config.button_1_label}
            </Button>
          )}
          {config.button_2_label && onClickProgramButtons && (
            <Button
              data-cy="components_LandingPage_LandingPageBenefits_guide-register-button-2"
              variant="contained"
              color="secondary"
              sx={{ mx: 2, color: 'white', minWidth: '180px' }}
              onClick={() => {
                trackScreenTimeAndClick(
                  'Program Landing page - Benefits - Be a Guide',
                );
                onClickProgramButtons(true, pathname);
              }}
            >
              {config.button_2_label}
            </Button>
          )}
          {config.button_3_label && onClickProgramButtons && (
            <Button
              data-cy="components_LandingPage_LandingPageBenefits_trainee-register-button-3"
              variant="contained"
              color="info"
              onClick={() => {
                trackScreenTimeAndClick(
                  'Program Landing page - Benefits - Be a Trainee',
                );
                onClickProgramButtons(false, pathname);
              }}
              sx={{ minWidth: '180px' }}
            >
              {config.button_3_label}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default LandingPageBenefits;
