// external
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

// components
import { IconCircle } from 'components/Icon';
import { AnimationWrapper } from 'components/AnimationWrapper';

// types
import { SanityLandingPageTextColumns } from '@guider-global/shared-types';

import { useMobileMediaQuery } from 'hooks';

interface ILandingPageTextColumnsProps {
  config: SanityLandingPageTextColumns;
}

export const LandingPageTextColumns: React.FunctionComponent<
  ILandingPageTextColumnsProps
> = ({ config }) => {
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const renderBlocks = () =>
    config?.blocks?.map((block, index) => {
      return (
        <Box
          key={`text-columns-block-${index}`}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: isMobile ? '100%' : '33%',
            maxWidth: '700px',
            mt: isMobile ? 3 : 2,
            px: isMobile ? 0 : 8,
          }}
        >
          <AnimationWrapper animationType="fade">
            <IconCircle
              icon={block.icon}
              color={theme.palette.secondary.dark}
              size="large"
            />
          </AnimationWrapper>
          <AnimationWrapper animationType="fade">
            <Typography variant="h6" sx={{ mb: 1.5 }}>
              {block.title}
            </Typography>
          </AnimationWrapper>
          <AnimationWrapper animationType="fade" animationDelay={250}>
            <Typography variant="body2" color="text.secondary">
              {block.description}
            </Typography>
          </AnimationWrapper>
        </Box>
      );
    });

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        py: 10,
        px: 2,
        textAlign: 'center',
      }}
    >
      <Typography component="h3" variant="h4">
        {config.title}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ mt: 1.5, width: '650px', maxWidth: '100%' }}
      >
        {config.subtitle}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: isMobile ? 'center' : 'flex-start',
          maxWidth: '90%',
          mt: 3,
        }}
      >
        {renderBlocks()}
      </Box>
    </Box>
  );
};

export default LandingPageTextColumns;
