import { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import {
  SanityFileSource,
  buildSanityFileUrl,
  buildSanityImageUrl,
} from '@guider-global/sanity-hooks';
import { Header } from '../Header';
import { ContentItemBySlugResult } from '@guider-global/sanity-types';

type Props = ContentItemBySlugResult[0];

export const PDF: FC<Props> = ({ content, metadata }) => {
  const fileUrl = content?.embedded_pdf
    ? buildSanityFileUrl({
        source: content?.embedded_pdf.asset as SanityFileSource,
      })
    : '';

  return (
    <>
      <Grid item xs={12} md={6} sx={{ mx: 'auto' }}>
        <Box
          component="img"
          src={
            content?.image && buildSanityImageUrl({ source: content?.image })
          }
          sx={{
            height: '280px',
            width: '100%',
            borderRadius: 1,
            objectFit: 'cover',
            mb: 3,
          }}
        />
        <Header {...{ heading: content?.title, type: metadata?.type }} />
        <Typography variant="body1" sx={{ mb: 3 }}>
          {content?.excerpt}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} sx={{ mx: 'auto' }}>
        <Box
          component="iframe"
          src={fileUrl}
          width="100%"
          height="70vh"
          sx={{
            borderRadius: 1,
            border: 'none',
          }}
        />
      </Grid>
    </>
  );
};
