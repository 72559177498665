import { FC } from 'react';

import { Box, Theme } from '@mui/material';
import { BsQuestionSquareFill } from 'react-icons/bs';

const FaqHeaderImage: FC = () => {
  // Styling
  const innerStyling = (theme: Theme) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.info.main,
    position: 'relative',
    opacity: '100%',
    width: theme.spacing(8),
    height: theme.spacing(8),
  });
  const middleStyling = (theme: Theme) => ({
    borderRadius: '50%',
    backgroundColor: `${theme.palette.info.main}80`,
    position: 'relative',
    padding: theme.spacing(2),
  });
  const outerStyling = (theme: Theme) => ({
    borderRadius: '50%',
    backgroundColor: `${theme.palette.info.main}14`,
    padding: theme.spacing(2),
  });
  const containerStyling = () => ({
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'minContent',
    height: 'minContent',
  });

  return (
    <Box sx={containerStyling}>
      <Box sx={outerStyling}>
        <Box sx={middleStyling}>
          <Box sx={innerStyling}>
            <BsQuestionSquareFill size="26px" color="#FFFFFF" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FaqHeaderImage;
