import {
  MenuItem as MUIMenuItem,
  MenuItemProps as MUIMenuItemProps,
  ListItemIcon as MuiListItemIcon,
} from '@mui/material';

export interface MenuItemProps {
  key?: string;
  action?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  href?: string;
  to?: string;
  component?: any;
  'data-cy': string;
  label?: string;
  children?: React.ReactNode;
  muiItemProps?: MUIMenuItemProps;
  icon?: React.ReactElement;
}

export const MenuItem = ({
  action,
  href,
  to,
  label,
  children,
  component,
  'data-cy': dataCy,
  muiItemProps,
  icon,
}: MenuItemProps) => {
  return (
    <MUIMenuItem
      onClick={action}
      href={href}
      component={component}
      to={to}
      data-cy={dataCy}
      {...muiItemProps}
    >
      {icon && (
        <MuiListItemIcon style={{ marginRight: '8px' }}>{icon}</MuiListItemIcon>
      )}
      {label}
      {children}
    </MUIMenuItem>
  );
};
