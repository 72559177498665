// external
import { Box, Card, Divider, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';
import { IconType } from 'react-icons';
import { MdFlag, MdSchool } from 'react-icons/md';

// internal
import { hexToRGB } from 'utils';

// components
import { AnimationWrapper } from 'components/AnimationWrapper';
import { NavigationToProgramButton } from './NavigationToProgramButton';

// types
import {
  Program,
  SanityProgramLandingPageHero,
} from '@guider-global/shared-types';

// hooks
import { useAuth } from '@guider-global/auth-hooks';
import { useMobileMediaQuery } from 'hooks';
import { getImageSource } from 'utils/getImageSource';

export interface ICallToActionConfig {
  title?: string;
  subtitle?: string;
  cta_button_label?: string;
}

interface IProgramLandingPageHeroProps {
  config: SanityProgramLandingPageHero;
  programDetails: Program['program_details'];
  setHeroHeight: React.Dispatch<React.SetStateAction<number>>;
  onClickProgramButtons: (isGuide?: boolean, pathname?: string) => void;
}

export const ProgramLandingPageHero: React.FunctionComponent<
  IProgramLandingPageHeroProps
> = ({ config, programDetails, setHeroHeight, onClickProgramButtons }) => {
  useAuth({});
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const heroRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    heroRef && heroRef.current && setHeroHeight(heroRef.current.clientHeight);
  }, [heroRef?.current?.clientHeight, setHeroHeight]);

  const renderCTA = useCallback(
    ({
      ctaConfig,
      role,
      icon,
      iconColor,
      buttonColor,
    }: {
      ctaConfig?: ICallToActionConfig;
      role: 'guide' | 'trainee';
      icon: IconType;
      iconColor: string;
      buttonColor: 'secondary' | 'info';
    }) => (
      <AnimationWrapper
        slideDirection="up"
        animationDelay={1000}
        slideOffset={10}
        sx={{
          display: 'flex',
          width: isMobile ? '100%' : '48%',
          mt: isMobile ? 3 : 0,
          maxWidth: isMobile ? '400px' : 'none',
        }}
      >
        <Card
          sx={{
            width: '100%',
            p: 3,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            '&::after': {
              content: '""',
              display: 'block',
              opacity: 0.8,
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              zIndex: 0,
              height: '35px',
              borderTop: '20px solid transparent',
              borderLeft:
                buttonColor === 'secondary'
                  ? `400px solid rgba(${hexToRGB(
                      theme.palette.secondary.main,
                    )}, 0.2)`
                  : `400px solid rgba(${hexToRGB(
                      theme.palette.info.main,
                    )}, 0.2)`,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              mb: 1.5,
              zIndex: 1,
              width: '100%',
            }}
          >
            <Box
              component={icon}
              color={iconColor}
              size={24}
              sx={{ width: 24, height: 24, minWidth: 24 }}
            />
            <Typography variant="h6" sx={{ ml: 2 }}>
              {ctaConfig?.title}
            </Typography>
          </Box>
          {ctaConfig?.subtitle && (
            <Typography
              variant="caption"
              sx={{ mb: 1.5, zIndex: 1, width: '100%', flex: 1 }}
            >
              {ctaConfig?.subtitle}
            </Typography>
          )}
          <NavigationToProgramButton
            ctaConfig={ctaConfig}
            buttonColor={buttonColor}
            role={role}
            onClickProgramButtons={onClickProgramButtons}
          />
        </Card>
      </AnimationWrapper>
    ),
    [
      isMobile,
      onClickProgramButtons,
      theme.palette.info.main,
      theme.palette.secondary.main,
    ],
  );

  const renderGuideCTA = () =>
    renderCTA({
      ctaConfig: config.guide_cta,
      role: 'guide',
      icon: MdFlag,
      iconColor: theme.palette.secondary.main,
      buttonColor: 'secondary',
    });

  const renderTraineeCTA = () =>
    renderCTA({
      ctaConfig: config.trainee_cta,
      role: 'trainee',
      icon: MdSchool,
      iconColor: theme.palette.info.main,
      buttonColor: 'info',
    });

  return (
    <Box
      ref={heroRef}
      sx={{
        width: '100%',
        pb: 6,
        pt: isMobile ? 6 : 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `radial-gradient(127.2% 127.2% at 50.03% 55.23%,
          rgba(0, 0, 0, 0.9) 0%,
          rgba(0, 0, 0, 0.108) 100%),
          url(${getImageSource({
            source: programDetails?.program_image,
            width: 1920,
          })})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          width: '700px',
          maxWidth: '95%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <AnimationWrapper
          animationType="fade"
          fadeDuration={1.5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            color="white"
            sx={{ mt: 3, textAlign: 'center' }}
          >
            {config.title}
          </Typography>
          <Divider
            sx={{
              width: '190px',
              height: '4px',
              border: 'none',
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '12px',
              mt: 3,
              mb: 3.5,
            }}
          />
        </AnimationWrapper>
        {config?.subtitle && (
          <AnimationWrapper
            animationType="fade"
            animationDelay={500}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography
              variant="body1"
              color="white"
              sx={{ mb: 3, mt: -0.5, textAlign: 'center', width: '90%' }}
            >
              {config.subtitle}
            </Typography>
          </AnimationWrapper>
        )}

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'flex-start' : 'space-between',
            alignItems: isMobile ? 'center' : 'stretch',
          }}
        >
          {renderGuideCTA()}
          {renderTraineeCTA()}
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramLandingPageHero;
