/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef } from 'react';
// external
import { Box, Button, Typography, useTheme } from '@mui/material';
import Typewriter from 'typewriter-effect';

// types
import { SanityOrganizationLandingPageHero } from '@guider-global/shared-types';
import { hexToRGB } from 'utils';

// components
import { AnimationWrapper } from 'components/AnimationWrapper';
import { useMixpanelEvents, useMobileMediaQuery } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectRegistrationType } from 'store/slices/appSlice';
import { OrganizationLandingPageHeroMedia } from '../OrganizationLandingPageHeroMedia';

interface IOrganizationLandingPageHeroProps {
  config: SanityOrganizationLandingPageHero;
  handleLearnMoreClick: () => void;
  setHeroHeight: React.Dispatch<React.SetStateAction<number>>;
}

export const OrganizationLandingPageHero: React.FunctionComponent<
  IOrganizationLandingPageHeroProps
> = ({ config, handleLearnMoreClick, setHeroHeight }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const registrationPageType = useAppSelector(selectRegistrationType);
  const media = config.organizationHeroMedia;
  const showLoginButtons = registrationPageType !== 'none';
  const { trackScreenTimeAndClick } = useMixpanelEvents();

  const displayNoMedia: boolean =
    media?.media === 'none' && media?.overrideDefaultHeroImage === true;

  const handleRegister = useCallback(() => {
    trackScreenTimeAndClick(
      'Organization Landing page - Hero - Register Button',
    );
    navigate('/register/account');
  }, [navigate, trackScreenTimeAndClick]);

  const isMobile = useMobileMediaQuery();

  const heroRef = useRef<null | HTMLDivElement>(null);

  const headerStyles = {
    fontWeight: 600,
    fontSize: isMobile ? '24px' : '48px',
    lineHeight: isMobile ? '32px' : 'auto',
  };

  useEffect(() => {
    heroRef && heroRef.current && setHeroHeight(heroRef.current.clientHeight);
  }, [heroRef?.current?.clientHeight, setHeroHeight]);

  const renderTitle = () => (
    <AnimationWrapper slideDirection="up" slideOffset={50} fadeDuration={2}>
      <Typography
        component="h1"
        variant="h3"
        sx={{
          textAlign: isMobile || displayNoMedia ? 'center' : 'left',
          ...headerStyles,
        }}
      >
        {config.dynamic_title ? (
          <>
            <Typography variant="h3" sx={headerStyles} component="span">
              {config.title_prefix}
            </Typography>
            <br />
            <Box
              sx={{
                color: theme.palette.secondary.dark,
                whiteSpace: 'nowrap',
                textDecoration: 'underline',
                textDecorationColor: `rgba(${hexToRGB(
                  theme.palette.secondary.dark,
                )}, 0.4)`,
                textDecorationThickness: '3px',
                textUnderlineOffset: isMobile ? 6 : 12,
                mb: isMobile ? 0.5 : 1,
              }}
            >
              <Typewriter
                options={{
                  strings: config.dynamic_list,
                  autoStart: true,
                  loop: true,
                  cursor: '',
                }}
              />
            </Box>
            <Typography variant="h3" sx={headerStyles} component="span">
              {config.title_suffix}{' '}
            </Typography>
          </>
        ) : (
          config.title
        )}
      </Typography>
    </AnimationWrapper>
  );

  const renderSubtitle = () =>
    config.subtitle && (
      <AnimationWrapper
        slideDirection="up"
        fadeDuration={2}
        animationDelay={500}
      >
        <Box
          sx={{
            mt: 4,
            pl: isMobile || displayNoMedia ? 0 : 3,
            pt: isMobile || displayNoMedia ? 3 : 0,
            textAlign: isMobile || displayNoMedia ? 'center' : 'left',
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: isMobile || displayNoMedia ? 'column' : 'row',
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              left: isMobile || displayNoMedia ? 'auto' : 0,
              top: 0,
              height: isMobile || displayNoMedia ? '4px' : '100%',
              width: isMobile || displayNoMedia ? '100px' : '4px',
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '12px',
            },
          }}
        >
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontWeight: 500,
            }}
          >
            {config.subtitle}
          </Typography>
        </Box>
      </AnimationWrapper>
    );

  const renderButtons = () => (
    <AnimationWrapper
      slideDirection="up"
      slideDuration={1.5}
      animationDelay={1000}
      slideOffset={10}
    >
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: displayNoMedia ? 'center' : 'flexStart',
        }}
      >
        {showLoginButtons && (
          <Button
            data-cy="components_LandingPage_OrganisationLandingPageHero_register-button"
            sx={{
              minWidth: '180px',
              ...(isMobile ? { mb: 3 } : { mr: 2 }),
            }}
            variant="contained"
            size="large"
            color="info"
            onClick={handleRegister}
          >
            {config.button_1_label}
          </Button>
        )}
        {config?.button_2_enable ? (
          <Button
            data-cy="components_LandingPage_OrganisationLandingPageHero_learn-more-button"
            color="inherit"
            sx={{ color: theme.palette.text.secondary, minWidth: '180px' }}
            variant="outlined"
            size="large"
            onClick={handleLearnMoreClick}
          >
            {config?.button_2_label}
          </Button>
        ) : undefined}
      </Box>
    </AnimationWrapper>
  );

  return (
    <>
      <Box
        ref={heroRef}
        sx={{
          backgroundColor: '#e5e5e5',
          m: isMobile ? 2 : 3,
          borderRadius: '40px',
          // overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage:
            'radial-gradient(rgba(0, 0, 0, 0.3) 0.45px, rgba(247, 247, 247, 0.7) 1px);',
          backgroundSize: '12px 12px',
        }}
      >
        <Box
          sx={{
            justifyContent: 'space-between',
            width: '1392px',
            maxWidth: '100%',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            background: `radial-gradient(
            circle,
            rgba(235, 235, 235, 1) 0%,
            rgba(235, 235, 235, 0.6) 80%,
            rgba(235, 235, 235, 0) 100%
          )`,
            px: isMobile ? 0 : 5,
          }}
        >
          <Box
            sx={{
              width: isMobile ? '100%' : '50%',
              py: '68px',
              px: isMobile ? 2 : 5,
              my: 'auto',
              mx: 'auto',
            }}
          >
            {renderTitle()}
            {renderSubtitle()}
            {renderButtons()}
          </Box>
          <OrganizationLandingPageHeroMedia media={media} />
        </Box>
      </Box>
    </>
  );
};

export default OrganizationLandingPageHero;
