import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

export interface HowItWorksVideoProps {
  videoUrl?: string;
}

const HowItWorksVideo: FunctionComponent<HowItWorksVideoProps> = ({
  videoUrl,
}) => {
  if (!videoUrl) return <></>;
  return (
    <Box
      sx={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        paddingBottom: '56.25%',
      }}
    >
      <Box
        component="iframe"
        src={videoUrl}
        title="How its works embedded video"
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          borderRadius: 1,
          border: 'none',
        }}
        allowFullScreen
      />
    </Box>
  );
};

export default HowItWorksVideo;
