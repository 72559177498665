/* eslint-disable camelcase */

import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';

import {
  SanityFileSource,
  buildSanityFileUrl,
  buildSanityImageUrl,
} from '@guider-global/sanity-hooks';
import { ContentItemBySlugResult } from '@guider-global/sanity-types';
import { Header } from '../Header';

type Props = ContentItemBySlugResult[0];

export const Audio: FC<Props> = ({ content, metadata }) => {
  const fileUrl = content?.audio_file
    ? buildSanityFileUrl({
        source: content?.audio_file?.asset as SanityFileSource,
      })
    : '';

  return (
    <>
      <Grid item xs={12} sm={8} md={6} sx={{ mx: 'auto' }}>
        <Box
          component="img"
          src={
            content?.image && buildSanityImageUrl({ source: content?.image })
          }
          sx={{
            height: '280px',
            width: '100%',
            borderRadius: 1,
            objectFit: 'cover',
            mb: 3,
          }}
        />
        <Header {...{ heading: content?.title, type: metadata?.type }} />
        <Typography variant="body1" sx={{ mb: 3 }}>
          {content?.excerpt}
        </Typography>
        <Box
          component="audio"
          controls
          src={fileUrl}
          sx={{
            borderRadius: 1,
            border: 'none',
            width: '100%',
            background: 'white',
          }}
        />
      </Grid>
    </>
  );
};
