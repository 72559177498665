import {
  Button,
  ButtonProps,
  Menu as MUIMenu,
  MenuProps as MUIMenuProps,
  PopoverVirtualElement,
} from '@mui/material';
import { MenuItems, MenuItemProps } from 'components';

export interface MenuProps {
  items: MenuItemProps[];
  anchorEl:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined;
  onMenuClickOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMenuClickClosed: (
    event?: unknown,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => void;
  hidden?: boolean;
  buttonProps?: ButtonProps;
  buttonDataCy?: string;
  children: React.ReactNode;
  menuProps?: MUIMenuProps;
}

export const Menu = ({
  items,
  anchorEl,
  onMenuClickOpen,
  onMenuClickClosed,
  hidden,
  buttonDataCy,
  buttonProps,
  children,
  menuProps,
}: MenuProps) => {
  if (hidden) return <></>;
  return (
    <>
      <Button
        id="menu-button"
        data-cy={buttonDataCy}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : 'false'}
        aria-controls="menu"
        onClick={onMenuClickOpen}
        variant="text"
        sx={{ color: 'inherit', textTransform: 'none' }}
        {...buttonProps}
      >
        {children}
      </Button>
      <MUIMenu
        anchorEl={anchorEl}
        aria-hidden={anchorEl ? 'true' : 'false'}
        open={Boolean(anchorEl)}
        onClose={onMenuClickClosed}
        id="menu"
        {...menuProps}
      >
        <MenuItems items={items} />
      </MUIMenu>
    </>
  );
};
