/* eslint-disable camelcase */
import React, { useCallback } from 'react';
// external
import {
  Box,
  Button,
  Card,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import { useLocation } from 'react-router-dom';

// Components
import { AnimationWrapper } from 'components/AnimationWrapper';
import { getImageSource } from 'utils/getImageSource';

// types
import { SanityLandingPageTextWithImage } from '@guider-global/shared-types';
import { ILoginRegisterButtonProps } from '../LandingPageContent';
import { Markdown } from 'components';

import { useMixpanelEvents, useMobileMediaQuery } from 'hooks';

interface ILandingPageTextWithImageProps extends ILoginRegisterButtonProps {
  config: SanityLandingPageTextWithImage;
}

export const LandingPageTextWithImage: React.FunctionComponent<
  ILandingPageTextWithImageProps
> = ({
  config,
  onClickProgramButtons,
  onClickRegisterButton,
  showLoginButtons,
}) => {
  const { trackScreenTimeAndClick } = useMixpanelEvents();
  // Styling
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  // Router
  const location = useLocation();
  const pathname = location.pathname;

  const renderBlocks = useCallback(
    () =>
      config?.blocks &&
      config.blocks.map((block, index) => {
        const isEven = index % 2 === 0;

        const positionStylesDesktop = isEven ? { mr: -10 } : { ml: -10 };
        const positionStylesMobile = {
          mt: -2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        };
        const positionStyles = isMobile
          ? positionStylesMobile
          : positionStylesDesktop;

        const imageBorderRadius = isEven ? '8px 0 0 8px' : '0 8px 8px 0';

        const rowFlexDirectionDesktop = isEven ? 'row' : 'row-reverse';
        const rowFlexDirection = isMobile
          ? 'column-reverse'
          : rowFlexDirectionDesktop;

        return (
          <Box
            key={`text-with-image-block-${block.title}`}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: rowFlexDirection,
              alignItems: 'center',
              mb: { xs: 8, md: 15 },
              '&:last-child': {
                mb: 0,
              },
            }}
          >
            <AnimationWrapper
              animationType={isMobile ? 'fade' : 'both'}
              slideDirection={isEven ? 'right' : 'left'}
              sx={{
                width: '550px',
                maxWidth: '95%',
                zIndex: { xs: 5, md: 10 },
                ...positionStyles,
              }}
            >
              <Card
                sx={{
                  ...(isMobile && {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }),
                  width: '100%',
                  p: 6,
                  px: { xs: 2, md: 6 },
                }}
              >
                <Divider
                  sx={{
                    width: '100px',
                    height: '4px',
                    border: 'none',
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: '12px',
                  }}
                />
                <Typography component="h2" variant="h4" sx={{ py: 4 }}>
                  {block.title}
                </Typography>

                <Typography
                  component="div"
                  variant="body2"
                  sx={{
                    '> p': {
                      margin: 0,
                    },
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <Markdown>{block?.description ?? ''}</Markdown>
                </Typography>

                {!isMobile && showLoginButtons && block.show_buttons && (
                  <Box sx={{ mt: 4 }}>
                    {block.button_1_label && (
                      <Button
                        data-cy="components_LandingPage_LandingPageWithImage_register-button-1"
                        variant="contained"
                        color="info"
                        onClick={() => {
                          trackScreenTimeAndClick(
                            'Organization Landing page - Text with Images - Register Button',
                          );
                          onClickRegisterButton();
                        }}
                      >
                        {block.button_1_label}
                      </Button>
                    )}
                    {block.button_2_label && onClickProgramButtons && (
                      <Button
                        data-cy="components_LandingPage_LandingPageWithImage_guide-register-button-2"
                        variant="contained"
                        color="secondary"
                        sx={{ mr: 2, color: 'white' }}
                        onClick={() => {
                          trackScreenTimeAndClick(
                            'Program Landing page - Text with Images - Be a Guide',
                          );
                          onClickProgramButtons(true, pathname);
                        }}
                      >
                        {block.button_2_label}
                      </Button>
                    )}
                    {block.button_3_label && onClickProgramButtons && (
                      <Button
                        data-cy="components_LandingPage_LandingPageWithImage_trainee-register-button-3"
                        variant="contained"
                        color="info"
                        onClick={() => {
                          trackScreenTimeAndClick(
                            'Program Landing page - Text with Images - Be a Trainee',
                          );
                          onClickProgramButtons(false, pathname);
                        }}
                      >
                        {block.button_3_label}
                      </Button>
                    )}
                  </Box>
                )}
              </Card>
            </AnimationWrapper>
            <AnimationWrapper
              animationType="fade"
              sx={{
                width: { xs: '500px', md: '55%' },
                maxWidth: '90%',
                height: { xs: '50vw', md: '30vw' },
                backgroundImage: `url('${getImageSource({
                  source: block.image,
                  width: 1400,
                })}')`,
                backgroundSize: 'cover',
                zIndex: { xs: 10, md: 5 },
                boxShadow: { xs: 'auto', md: 'none' },
                borderRadius: { xs: 'auto', md: imageBorderRadius },
                backgroundPosition: 'center',
              }}
            ></AnimationWrapper>
          </Box>
        );
      }),
    [
      config.blocks,
      isMobile,
      onClickProgramButtons,
      onClickRegisterButton,
      trackScreenTimeAndClick,
      pathname,
      showLoginButtons,
      theme.palette.secondary.main,
    ],
  );

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        background: '#f5f5f5',
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 1,
          width: '0px',
          height: '0px',
          borderTop: '120px solid #ffffff',
          borderRight: '50vw solid transparent',
          borderBottom: '120px solid transparent',
          borderLeft: '50vw solid #ffffff',
        },
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          bottom: 0,
          left: 0,
          zIndex: 1,
          width: '0px',
          height: '0px',
          borderTop: '120px solid transparent',
          borderRight: '50vw solid #ffffff',
          borderBottom: '120px solid #ffffff',
          borderLeft: '50vw solid transparent',
        },
        py: { xs: 10, md: 18 },
        mt: { xs: 4, md: 8 },
        mb: { xs: 0, md: '64px' },
      }}
    >
      {renderBlocks()}
    </Box>
  );
};

export default LandingPageTextWithImage;
