import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import { HowItWorksButtons as SanityHowItWorksButtons } from '@guider-global/shared-types';
import { ILoginRegisterButtonProps } from 'components/LandingPage/LandingPageContent';
import { useMixpanelEvents } from 'hooks';

export interface HowItWorksButtonsProps extends ILoginRegisterButtonProps {
  buttons: SanityHowItWorksButtons;
}

const HowItWorksButtons: FunctionComponent<HowItWorksButtonsProps> = ({
  buttons,
  onClickProgramButtons,
  onClickRegisterButton,
  showLoginButtons,
}) => {
  const { trackScreenTimeAndClick } = useMixpanelEvents();
  // Router
  const location = useLocation();
  const pathname = location.pathname;

  if (!buttons.showButtons) return null;

  if (
    (buttons.guideRegistrationButtonLabel ||
      buttons.traineeRegistrationButtonLabel) &&
    onClickProgramButtons
  )
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        <Button
          data-cy="components_LandingPage_LandingPageHowItWorks_HowItWorksButtons_guide-registration-button"
          variant="contained"
          color="secondary"
          onClick={() => {
            trackScreenTimeAndClick(
              'Program Landing page - How it works - Be a Guide',
            );
            onClickProgramButtons(true, pathname);
          }}
          sx={(theme) => ({
            padding: theme.spacing(1, 5),
          })}
        >
          {buttons.guideRegistrationButtonLabel}
        </Button>
        <Button
          data-cy="components_LandingPage_LandingPageHowItWorks_HowItWorksButtons_trainee-registration-button"
          variant="contained"
          color="info"
          onClick={() => {
            trackScreenTimeAndClick(
              'Program Landing page - How it works - Be a Trainee',
            );
            onClickProgramButtons(false, pathname);
          }}
          sx={(theme) => ({
            padding: theme.spacing(1, 5),
          })}
        >
          {buttons.traineeRegistrationButtonLabel}
        </Button>
      </Box>
    );

  if (!showLoginButtons) return null;

  return (
    <Box>
      <Button
        data-cy="components_LandingPage_LandingPageHowItWorks_HowItWorksButtons_registration-button"
        variant="contained"
        color="info"
        onClick={() => {
          trackScreenTimeAndClick(
            'Organization Landing page - How it works - Register Button',
          );
          onClickRegisterButton();
        }}
        sx={(theme) => ({
          padding: theme.spacing(1, 5),
        })}
      >
        {buttons.registrationButtonLabel}
      </Button>
    </Box>
  );
};

export default HowItWorksButtons;
