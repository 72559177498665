import { FC, PropsWithChildren } from 'react';

import { Typography } from '@mui/material';

const HowItWorksHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Typography
        variant="h3"
        sx={{
          justifySelf: { xs: 'start', md: 'center' },
          paddingLeft: { xs: 3, md: 0 },
          color: 'white',
        }}
        textAlign={{ xs: 'left', md: 'center' }}
      >
        {children}
      </Typography>
    </>
  );
};

export default HowItWorksHeader;
