import { Box, Grid } from '@mui/material';
import { PortableText } from '@portabletext/react';
import { buildSanityImageUrl } from '@guider-global/sanity-hooks';
import { Header } from '../Header';
import { ContentItemBySlugResult } from '@guider-global/sanity-types';

type Props = ContentItemBySlugResult[0];

export const Article: React.FC<Props> = ({ content, metadata }) => {
  return (
    <Grid item xs={12} md={6} sx={{ mb: 8, mx: 'auto' }}>
      <Box
        component="img"
        src={content?.image && buildSanityImageUrl({ source: content?.image })}
        sx={{
          height: '280px',
          width: '100%',
          borderRadius: 1,
          objectFit: 'cover',
          mb: 3,
        }}
      />
      <Header {...{ heading: content?.title, type: metadata?.type }} />
      <Box component={PortableText} value={content?.article_content ?? []} />
    </Grid>
  );
};
