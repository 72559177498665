import { MaintenanceView } from 'components/MaintenanceView';
import { useMobileMediaQuery } from 'hooks';
import { useIntercom } from 'react-use-intercom';

export interface MaintenanceWrapperProps {
  isIntercom?: boolean;
}

export const MaintenanceWrapper = ({ isIntercom }: MaintenanceWrapperProps) => {
  const isMobile = useMobileMediaQuery();
  const {
    show: showIntercom,
    hide: hideIntercom,
    isOpen: isIntercomOpen,
  } = useIntercom();

  // TODO after SANITY V3 migration, link content to CMS
  // const { getBaseLanguage } = useSanityBaseLanguage({ getSilently: true });
  // const baseLanguage = getBaseLanguage();
  // const { title, description } = baseLanguage?.maintenancePage || {};
  const heading = 'Platform Updating...';
  const alertContent = 'Please refresh your browser in a few seconds.';
  const supportButtonLabel = 'Contact Support';
  const href = `mailto:${process.env.REACT_APP_GUIDER_SUPPORT_EMAIL}`;
  const description = isIntercom
    ? 'If this issue persists please contact support via intercom below.'
    : 'If this issue persists please contact support';

  const handleClickSupport = () => {
    if (isIntercomOpen) {
      return hideIntercom();
    }
    return showIntercom();
  };
  return (
    <MaintenanceView
      heading={heading}
      alertContent={alertContent}
      supportButtonLabel={supportButtonLabel}
      description={description}
      isMobile={isMobile}
      {...(isIntercom ? { onClickSupport: handleClickSupport } : { href })}
    />
  );
};
