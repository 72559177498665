import React from 'react';

import { Box, Typography } from '@mui/material';

import { SanityLandingPageFaqs } from '@guider-global/shared-types';

import { AnimationWrapper } from 'components/AnimationWrapper';
import { Masonry } from '@mui/lab';
import { Markdown } from 'components';

export interface FaqQuestionGridProps {
  questions: SanityLandingPageFaqs['faqQuestions'];
}

const FaqQuestionGrid: React.FC<FaqQuestionGridProps> = ({ questions }) => {
  return (
    <Box py={8}>
      <Masonry columns={2} spacing={6}>
        {questions.map(({ question, answer }, index) => {
          return (
            <AnimationWrapper
              animationType="fade"
              key={`faq-question-grid-${index}`}
            >
              <Box
                sx={(theme) => ({
                  maxWidth: theme.breakpoints.values.lg,
                })}
              >
                <Typography variant="body1" pb={1}>
                  {question}
                </Typography>
                <Typography
                  variant="body2"
                  color={'textSecondary'}
                  component={'span'}
                  sx={{
                    '> p': {
                      margin: 0,
                    },
                    'ul, ol': {
                      my: -2,
                    },
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <Markdown>{answer ?? ''}</Markdown>
                </Typography>
              </Box>
            </AnimationWrapper>
          );
        })}
      </Masonry>
    </Box>
  );
};

export default FaqQuestionGrid;
