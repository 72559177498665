import { FunctionComponent } from 'react';

import { HowItWorksMedia as SanityHowItWorksMedia } from '@guider-global/shared-types';
import { useMobileMediaQuery } from 'hooks';

import HowItWorksVideo from './HowItWorksVideo';
import HowItWorksImage from './HowItWorksImage';
import { AnimationWrapper } from 'components/AnimationWrapper';

export interface HowItWorksMediaProps {
  media: SanityHowItWorksMedia;
}

const HowItWorksMedia: FunctionComponent<HowItWorksMediaProps> = ({
  media,
}) => {
  // Prop Derivation
  const { media: mediaType, image, video: videoUrl } = media;
  // Styles
  const isMobile = useMobileMediaQuery();

  if (mediaType === 'video')
    return (
      <AnimationWrapper
        animationType={isMobile ? 'fade' : 'both'}
        animationDelay={isMobile ? 500 : undefined}
        slideDirection="right"
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <HowItWorksVideo videoUrl={videoUrl} />
      </AnimationWrapper>
    );
  return (
    <AnimationWrapper
      animationType={isMobile ? 'fade' : 'both'}
      animationDelay={isMobile ? 500 : undefined}
      slideDirection="right"
    >
      <HowItWorksImage image={image} />
    </AnimationWrapper>
  );
};

export default HowItWorksMedia;
