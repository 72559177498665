import { FunctionComponent } from 'react';

import { HowItWorksStep as SanityHowItWorksStep } from '@guider-global/shared-types';
import { useMobileMediaQuery } from 'hooks';

import { Typography, useTheme } from '@mui/material';
import { AnimationWrapper } from 'components/AnimationWrapper';

export interface HowItWorksStepProps {
  step: SanityHowItWorksStep;
  position: number;
}

const HowItWorksStep: FunctionComponent<HowItWorksStepProps> = ({
  step,
  position,
}) => {
  // Styles
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  return (
    <AnimationWrapper
      animationType="fade"
      animationDelay={isMobile ? 500 : undefined}
      sx={{
        width: '100%',
        boxShadow: 2,
        padding: theme.spacing(2),
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: theme.spacing(1),
        borderRadius: '4px',
        background: '#424242',
        border: `0 solid ${theme.palette.secondary.main}`,
        borderLeftWidth: '4px',
        [theme.breakpoints.down('md')]: {
          alignItems: 'stretch',
        },
      }}
    >
      <Typography variant="body1" color={'common.white'}>
        {position}. {step.title}
      </Typography>
      <Typography
        variant="caption"
        color="primary.contrastText"
        sx={(theme) => ({ marginLeft: theme.spacing(2.25), color: 'white' })}
      >
        {step.description}
      </Typography>
    </AnimationWrapper>
  );
};

export default HowItWorksStep;
