import React, { PropsWithChildren } from 'react';

import { SanityLandingPageFaqs } from '@guider-global/shared-types';
import { useMobileMediaQuery } from 'hooks';

import { Box } from '@mui/material';
import FaqHeaderImage from './FaqHeaderImage';
import FaqHeader from './FaqHeader';
import FaqQuestionGrid from './FaqQuestionGrid';
import { AnimationWrapper } from 'components/AnimationWrapper';
import FaqQuestionColumn from './FaqQuestionColumn';

export interface LandingPageFaqsProps {
  config: SanityLandingPageFaqs;
}

const LandingPageFaqs: React.FC<PropsWithChildren<LandingPageFaqsProps>> = ({
  config,
}) => {
  // Styles
  const isMobile = useMobileMediaQuery();

  // Get map of content
  const { faqQuestions, title } = config;

  return (
    <Box
      sx={(theme) => ({
        marginTop: { xs: theme.spacing(8), md: theme.spacing(16) },
        marginBottom: { xs: theme.spacing(8), md: theme.spacing(16) },
        marginLeft: { xs: theme.spacing(2), md: '10%' },
        marginRight: { xs: theme.spacing(2), md: '10%' },
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
      })}
    >
      <AnimationWrapper
        animationType="fade"
        animationDelay={isMobile ? 500 : undefined}
      >
        <FaqHeaderImage />
      </AnimationWrapper>

      <AnimationWrapper
        animationType="fade"
        animationDelay={isMobile ? 500 : undefined}
        sx={{
          alignSelf: 'stretch',
        }}
      >
        <Box pt={1.5}>
          <FaqHeader>{title}</FaqHeader>
        </Box>
      </AnimationWrapper>
      {isMobile ? (
        <FaqQuestionColumn questions={faqQuestions} />
      ) : (
        <FaqQuestionGrid questions={faqQuestions} />
      )}
    </Box>
  );
};

export default LandingPageFaqs;
