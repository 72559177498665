import { SanityEmoji } from '@guider-global/shared-types';
import { Button, ButtonProps, Typography } from '@mui/material';
import { Chip } from '@guider-global/ui';

export interface LanguageButtonProps extends ButtonProps {
  flag?: SanityEmoji;
  children?: React.ReactNode;
  selected?: boolean;
}

export const LanguageButton = ({
  flag,
  children,
  selected,
  ...buttonProps
}: LanguageButtonProps) => {
  return (
    <Button
      sx={{
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        pl: 2,
        textTransform: 'none',
        color: 'black',
      }}
      {...buttonProps}
    >
      <Typography variant="body1" textAlign="center">
        {flag?.native} {children}
      </Typography>
      {selected && <Chip label="Selected" color="success" size="small" />}
    </Button>
  );
};
