import { buildSanityImageUrl, useSettings } from '@guider-global/sanity-hooks';
import { OrganizationHeroMedia } from '@guider-global/shared-types';
import { Box } from '@mui/material';
import { AnimationWrapper } from 'components/AnimationWrapper';
import { useMobileMediaQuery } from 'hooks';

export type IOrganizationLandingPageHeroProps = {
  media?: OrganizationHeroMedia;
};

export function OrganizationLandingPageHeroMedia({
  media,
}: IOrganizationLandingPageHeroProps) {
  const isMobile = useMobileMediaQuery();
  const { settings } = useSettings({
    localeCode: 'en_GB',
  });

  const defaultImage =
    settings?.static_media?.landing_page?.organization_hero_image;

  const displayImage =
    media &&
    media.media === 'image' &&
    media.image &&
    media.overrideDefaultHeroImage;
  const displayVideo =
    media &&
    media.media === 'video' &&
    media.video &&
    media.overrideDefaultHeroImage;
  const displayNone =
    media && media.media === 'none' && media.overrideDefaultHeroImage;

  if (displayImage && media.image) {
    return (
      <AnimationWrapper
        animationType="fade"
        fadeDuration={isMobile ? 1 : 2}
        screenHeightTrigger={30}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignSelf:
            media?.transparentImage && media?.verticalPosition === 'bottom'
              ? 'flex-end'
              : 'center',
          width: isMobile ? '100%' : '50%',
          height: '80%',
          px:
            media?.transparentImage && media?.verticalPosition === 'bottom'
              ? isMobile
                ? 0
                : 5
              : isMobile
              ? 2
              : 5,
          py:
            media?.transparentImage && media?.verticalPosition === 'bottom'
              ? 0
              : isMobile
              ? 2
              : '64px',
          overflow: 'hidden',
        }}
      >
        <Box
          component="img"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius:
              media?.transparentImage && media.verticalPosition === 'bottom'
                ? '0'
                : '40px',
          }}
          src={buildSanityImageUrl({
            source: media.image,
            width: 1000,
          })}
          alt={media.imageAltText ?? ''}
        ></Box>
      </AnimationWrapper>
    );
  }

  if (displayVideo) {
    return (
      <AnimationWrapper
        animationType="fade"
        fadeDuration={isMobile ? 1 : 2}
        screenHeightTrigger={30}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'center',
          width: isMobile ? '100%' : '50%',
          px: isMobile ? 2 : 4,
          py: isMobile ? 2 : 4,
          overflow: 'hidden',
          height: '340px',
        }}
      >
        <Box
          component={'iframe'}
          src={media.video}
          title="Organisation landing page video"
          sx={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            borderRadius: '40px',
          }}
        />
      </AnimationWrapper>
    );
  }

  return (
    <AnimationWrapper
      animationType="fade"
      fadeDuration={isMobile ? 1 : 2}
      screenHeightTrigger={30}
      sx={{
        display: displayNone ? 'none' : 'flex',
        alignSelf: 'flex-end',
        width: isMobile ? '100%' : '50%',
      }}
    >
      {defaultImage && (
        <Box
          component="img"
          sx={{ maxWidth: '100%', alignItems: 'flex-end' }}
          src={buildSanityImageUrl({
            source: defaultImage,
            width: 1000,
          })}
          alt="video conference image"
        ></Box>
      )}
    </AnimationWrapper>
  );
}
