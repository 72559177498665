import { FunctionComponent } from 'react';

import { SanityLandingPageHowItWorks } from '@guider-global/shared-types';
import { useMobileMediaQuery } from 'hooks';

import { Box } from '@mui/material';
import { backgroundDots } from 'styles';
import HowItWorksButtons from './HowItWorksButtons';
import HowItWorksHeader from './HowItWorksHeader';
import HowItWorksStepsRow from './HowItWorksSteps';
import HowItWorksDescription from './HowItWorksDescription';
import HowItWorksMedia from './HowItWorksMedia';
import { AnimationWrapper } from 'components/AnimationWrapper';

import { ILoginRegisterButtonProps } from '../LandingPageContent';

export interface LandingPageHowItWorksProps extends ILoginRegisterButtonProps {
  config: SanityLandingPageHowItWorks;
}

const LandingPageHowItWorks: FunctionComponent<LandingPageHowItWorksProps> = ({
  config,
  onClickProgramButtons,
  onClickRegisterButton,
  showLoginButtons,
}) => {
  // Prop Derivation
  const { title, howItWorks } = config;
  // Styling
  const isMobile = useMobileMediaQuery();

  return (
    <Box
      sx={{
        backgroundColor: '#333333',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        backgroundImage: backgroundDots,
        backgroundSize: '12px 12px',
        pt: { xs: 8, md: 12.25 },
        pb: { xs: 8, md: 11.25 },
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridAutoFlow: 'row',
          alignItems: 'center',
          justifyItems: 'center',
          gap: theme.spacing(6),
          maxWidth: theme.breakpoints.values.lg,
          width: '100%',
          [theme.breakpoints.down('md')]: {
            gap: theme.spacing(4),
          },
        })}
      >
        <HowItWorksHeader>
          <AnimationWrapper
            animationType="fade"
            animationDelay={isMobile ? 500 : undefined}
          >
            {title}
          </AnimationWrapper>
        </HowItWorksHeader>

        <HowItWorksStepsRow steps={howItWorks.howItWorksSteps} />
        <Box
          sx={(theme) => ({
            width: '100%',
            padding: theme.spacing(1.5, 0),
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr',
            gap: theme.spacing(7.75),
            alignItems: 'flex-start',
            justifyItems: 'start',
            justifyContent: 'center',
            px: 3,
            [theme.breakpoints.down('md')]: {
              gridTemplateColumns: '100%',
              gridTemplateRows: 'auto auto',
              gap: theme.spacing(6),
            },
          })}
        >
          <HowItWorksMedia media={howItWorks.howItWorksMedia} />
          <AnimationWrapper
            animationType={isMobile ? 'fade' : 'both'}
            animationDelay={isMobile ? 500 : undefined}
            slideDirection="left"
            sx={{
              width: '100%',
            }}
          >
            <HowItWorksDescription
              description={howItWorks.howItWorksDescription}
            />
          </AnimationWrapper>
        </Box>

        {!isMobile && (
          <HowItWorksButtons
            buttons={howItWorks.howItWorksButtons}
            onClickProgramButtons={onClickProgramButtons}
            onClickRegisterButton={onClickRegisterButton}
            showLoginButtons={showLoginButtons}
          />
        )}
      </Box>
    </Box>
  );
};

export default LandingPageHowItWorks;
